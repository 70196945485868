import { allapps } from "./products/AllApps";
import { autocad } from "./products/AutoCAD";
import { civil3d } from "./products/Civil3D";
import { revit } from "./products/Revit";


const productsData = [
    autocad,
    revit,
    civil3d,
    allapps
];


export default productsData