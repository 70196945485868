

export const autocad =  {
  title: "AutoCAD 1-Year License Key (2018-2025) for PC",
  shortDescription:
    "Get a 1-year license key for AutoCAD (2018-2025) for PC. Perfect for professionals and students looking to enhance their design projects.",
  longDescription:
    "Unlock a full year of AutoCAD (2018-2025) for your PC with this license key. Designed for architects, engineers, and designers, AutoCAD provides a comprehensive suite of drafting, modeling, and annotation tools necessary for creating detailed, precision designs. This subscription includes access to both 2D and 3D modeling features, as well as a variety of productivity tools for architectural and structural design. Compatible with major Windows versions, this 1-year license ensures users stay current with the latest enhancements, tools, and updates from Autodesk.",
  moreLongDescription:
    "AutoCAD 1-Year License Key (2018-2025) for PC provides essential access to Autodesk’s industry-standard design software, widely used across architecture, engineering, and construction. This license offers a full suite of 2D drafting and 3D modeling capabilities, allowing professionals to create accurate technical drawings, architectural layouts, and structural models with ease. With precision drafting tools, dynamic blocks, and layer management, AutoCAD supports streamlined project workflows, enabling users to produce consistent, high-quality designs. Additional features, such as advanced 3D visualization, parametric constraints, and customizable tool palettes, equip users to handle complex design tasks efficiently. Perfect for students and professionals, the 1-year license provides reliable software performance and includes regular updates, ensuring compatibility with the latest technology in design and drafting. Ideal for users working on construction documents, mechanical layouts, and building models, this license key delivers the tools needed to bring design visions to life with accuracy and control.",
  img: "/products/AutoCAD-2022-Software.webp",
  price: "$199",
  gtin: "9781501523175",
  comparedPrice: "$299",
  checkoutUrl: "https://t.co/RKkGOH17aD",
  slug: "autocad-1-year-license-keys-2018-2025",
  altText:
    "AutoCAD 1-Year License Key (2018-2025) for PC - Drafting and Drawing Software",
  metaTitle:
    "Buy AutoCAD 1-Year License Key (2018-2025) for PC - Drafting Software",
  metaDescription:
    "Purchase a 1-year AutoCAD license key (2018-2025) for PC. Enhance your drafting and design projects with industry-leading software. Available now at $199.",
  offers:[
      {
        id: 1,
        title: 'Autocad 2024 1 year',
        subtitle: 'LIMITED OFFER',
        price: 199.00,
        originalPrice: 299.00,
        image: '/products/AutoCAD-2022-Software.webp',
        badge: 'New',
        type:"Only Product",
        checkoutUrl:"https://t.co/RKkGOH17aD"
      },
      {
        id: 2,
        title: 'YourCad Special Offer <span class=" text-sm " >(Autocad + Revit + Civil 3d) 1-year</span>',
        subtitle: 'LIMITED OFFER',
        price: 399.00,
        originalPrice: 499.00,
        badge: 'Best Value',
        image: '/products/Autodesk-All-Products.webp',
        type:"Pack",
        checkoutUrl:"https://t.co/VawXfORz3F"
      },
    ],   
    testimony : {
      name: "Michael Chen",
      picture: "/profiles/img1.png",
      rate: 5,
      testimonial: `"Being in the field of civil engineering, precision and innovation are non-negotiable. Youcad's budget-friendly Autodesk solutions have proven to be my go-to. It's like having a customized tool for every project, and the cost-effectiveness is a huge bonus."`,
      status: "Civil Engineer",
    },  
  keywords: [
    "AutoCAD license key",
    "AutoCAD 1-year subscription",
    "AutoCAD for PC",
    "AutoCAD software 2018-2025",
    "AutoCAD drafting tools",
    "AutoCAD design software",
    "AutoCAD engineering tools",
    "AutoCAD 3D modeling",
    "AutoCAD architecture software",
    "AutoCAD 2018-2025 license for PC",
    "AutoCAD annual subscription",
    "AutoCAD PC license download",
    "AutoCAD precision tools",
    "AutoCAD 2D and 3D drafting",
    "AutoCAD building design software",
    "AutoCAD CAD tools",
    "AutoCAD professional tools",
    "AutoCAD software for architects",
    "AutoCAD structural design",
    "AutoCAD engineering and drafting",
    "AutoCAD for designers and engineers",
    "AutoCAD subscription plan",
    "AutoCAD 1-year PC license",
    "AutoCAD building modeling",
    "AutoCAD software for construction",
    "AutoCAD advanced tools",
    "AutoCAD for architectural design"
  ],
  availability: "In Stock",
  sku: "AUTOCAD-1YR-2025-PC",
  brand: "Autodesk",
  category: "Software",
  productType: "License",
  rating: 5,
  reviewCount: 157,
  structuredData: {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": "AutoCAD 1-Year License Key (2018-2025) for PC",
    "image": "/products/AutoCAD-2022-Software.webp",
    "description": "Unlock a full year of AutoCAD (2018-2025) for your PC with this license key. Ideal for architects, engineers, and designers, this drafting and drawing software provides top-tier tools for creating precision designs and technical drawings.",
    "sku": "AUTOCAD-1YR-2025-PC",
    "brand": {
      "@type": "Brand",
      "name": "Autodesk"
    },
    "offers": {
      "@type": "Offer",
      "url": "https://t.co/RKkGOH17aD",
      "priceCurrency": "USD",
      "price": "199",
      "priceValidUntil": "2025-12-31",
      "availability": "https://schema.org/InStock"
    },
    "category": "Software",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "reviewCount": "157"
    },
    "additionalProperty": [
      {
        "@type": "PropertyValue",
        "name": "Operating System",
        "value": "Windows"
      },
      {
        "@type": "PropertyValue",
        "name": "Operating System",
        "value": "macOS"
      },
      {
        "@type": "PropertyValue",
        "name": "License Type",
        "value": "1-Year License Key"
      },
      {
        "@type": "PropertyValue",
        "name": "Compatible Versions",
        "value": "AutoCAD 2018-2025"
      }
    ],
    "seller": {
      "@type": "Organization",
      "name": "Your CAD Store",
      "url": "https://yourcad.store/"
    }
  },  
    specification:[
      {
        prop:"Operating Systems Supported",
        value:"DOS, Linux, MAC, Windows"
      },
      {
        prop:"Place of Origin",
        value:"United States"
      },
      {
        prop:"Products Status",
        value:"Stock"
      },
      {
        prop:"Type",
        value:"Graphics & Multimedia"
      },
      {
        prop:"Version Type",
        value:"Home"
      },
      {
        prop:"Model Number",
        value:"AutoCAD"
      },
      {
        prop:"Product name",
        value:"AutoCAD 1 Year"
      },
      {
        prop:"Activation",
        value:"100% Activation Online Globally"
      },
      {
        prop:"Warranty",
        value:"Within the subscription time"
      },
      {
        prop:"Shipping Method",
        value:"Ali Chat or email"
      },
      {
        prop:"Language",
        value:"Multi-language"
      },
      {
        prop:"Support",
        value:"24*7 Online Chat Support"
      },
      {
        prop:"Download",
        value:"Official Genuine Download"
      },
      {
        prop:"Services",
        value:"24/7 Service Support"
      },
      {
        prop:"Usage",
        value:"Bind to the official website"
      },
      {
        prop:"Application",
        value:"Server Operating System"
      },
    ]
  }